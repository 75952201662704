angular.module('sq.user.signupCtrl', ['sq.user.auth'])
.controller('SignupCtrl', function(Auth, minPasswordLength, $window, $log, 
$location, $q, $scope, $timeout, $cacheFactory, $routeParams)  {
  var vm = this;

  vm.user = {};
  vm.errors = {};
  vm.success = false;
  vm.submitted = false;
  vm.minPasswordLength = minPasswordLength;

  

  vm.register = function(form) {
    $window.requestAnimationFrame(() => {
      vm.submitted = true;

      Auth.register({
        name: vm.user.name.trim(),
        email: vm.user.email.trim(),
        password: vm.user.password,
      }).then( function() {
        _complete();
      }, (caughtErr) => {
        $timeout(() => {
          if (caughtErr) {
            var err = caughtErr.data;

            if (err && err.errors) {
              //Update validity of form fields that match the mongoose errors
              angular.forEach(err.errors, (error, field) => {
                form[field].$setValidity(field, false);
               // console.info('error for field', form, form[field][0], angular.element(form[field][0]));
                  form[field].$error.server = error.message;
                 // _dontReuseValue(angular.element(form[field][0]), form[field].$modelValue);
                });
            } else {
              $log.error(new Error(`Something went wrong, received an error object with data
                property, but no data.errors property`));
            }
          }

          vm.submitted = false;

          return $q.reject(caughtErr);
        }, 200);
      });
    });
  };

  vm.loginOauth = (provider) => {
    $window.location.href = '/auth/' + provider;
  };

  function _complete() {
    $window.requestAnimationFrame(() => {
      vm.success = true;

      $timeout(() => {
        if ($routeParams.noredir) {
          $location.url('/home');
        } else if ($routeParams.path) {
          $location.path($routeParams.path).search($routeParams.params || null);
        } else {
          $window.history.back();
        }
      }, 200);
    });
  }
});
